var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import _ from "lodash";
import { E as EventBus, T as TableCellSelector, S as SheetClip } from "./app.js";
import { n as normalizeComponent } from "./index.js";
import { d as PROMO_TACTIC_MULTIBUY, e as PROMO_TACTIC_OOG_MULTIBUY } from "./others.js";
import "vue";
import "@kraftheinz/common";
import "numeral";
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "colorful-editable-table__container", style: {
    width: _vm.isShowDeltaField ? "calc(" + _vm.tblWidth + " - 170px)" : _vm.tblWidth
  } }, [_c("div", { directives: [{ name: "scroll", rawName: "v-scroll", value: _vm.handleScroll, expression: "handleScroll" }], staticClass: "underlying-table__container", attrs: { "id": "underlying-table" } }, [_c("a-dropdown", { attrs: { "trigger": ["contextmenu"] } }, [_c("table", { directives: [{ name: "click-outside", rawName: "v-click-outside", value: _vm.onEntryTableBlur, expression: "onEntryTableBlur" }], ref: "poTbl", staticClass: "colorful-editable-table", class: [
    "po-table--" + _vm.tblName,
    { "colorful-editable-table--collapsed": _vm.isCollapsed }
  ] }, [_c("tr", _vm._l(_vm.tblColumns, function(header, index) {
    return _c("th", { key: index, staticClass: "tcs-ignore", class: [
      _vm.invisibleColumns.includes(index) ? "d-none" : "",
      "th-" + header.type,
      header.type == "criteria-group" ? _vm.isCollapsed ? "" : "d-none" : ""
    ], style: {
      minWidth: header.width,
      backgroundColor: header.headerBgColor,
      textAlign: header.align,
      minHeight: "33px"
    } }, [_vm.tblName == "base" && index < 2 ? [_vm._v(" " + _vm._s(header.title) + " ")] : _c("select-input", { attrs: { "class-name": "fs-12", "data-source": _vm.promoTacticData, "source": "id", "source-label": "description", "value": header.title, "size": "small", "disabled": _vm.disableHeader, "allow-clear": _vm.allowClearHeader }, on: { "change": function($event) {
      return _vm.onChangeHeader(index - _vm.gapX, $event);
    } } })], 2);
  }), 0), _vm._l(_vm.tblRows, function(rowConfig, rowIndex) {
    return _c("tr", { key: rowIndex }, [rowConfig.criteriaGroup && rowConfig.criteriaGroup.rowSpan > 0 ? _c("td", { staticClass: "criteria-group tcs-ignore", class: [_vm.isCollapsed ? "" : "d-none"], attrs: { "rowspan": rowConfig.criteriaGroup.rowSpan } }, [_vm._v(" " + _vm._s(rowConfig.criteriaGroup.text) + " ")]) : _vm._e(), rowConfig.criteria ? _c("td", { staticClass: "criteria tcs-ignore" }, [_vm._v(" " + _vm._s(rowConfig.criteria) + " ")]) : _vm._e(), _vm._l(_vm.scenarioDetails, function(record, columnNo) {
      return _c("td", { key: rowConfig.key + "-" + columnNo, staticClass: "text-right", class: [
        "data-col-" + columnNo,
        _vm.invisibleColumns.includes(columnNo + _vm.gapX) ? "d-none" : "",
        _vm.tabIndex == _vm.getTabIndex(rowConfig.dataIndex, columnNo) ? "focusing" : "",
        record.overridden && record.overridden[rowConfig.dataIndex] ? "overridden" : rowConfig.dataIndex === "baseVolume" ? "neutral-7--text" : ""
      ], attrs: { "data-coord-x": columnNo + _vm.gapX, "data-coord-y": rowIndex + _vm.gapY, "data-plan-id": record["planId"], "data-option-id": record["id"], "data-field": rowConfig.dataIndex, "data-value": record[rowConfig.dataIndex] }, on: { "contextmenu": _vm.onContextMenuOpen } }, [_c("editable-label", { attrs: { "input-type": "text", "form-item": "", "error-type": "tooltip", "label": rowConfig.label, "value": record[rowConfig.dataIndex], "number-format": rowConfig.numberFormat, "suffix-input": rowConfig.suffixInput, "disabled": _vm.uneditable || rowConfig.disabled, "rules": rowConfig.validationRules, "focused-on": _vm.tabIndex == _vm.getTabIndex(rowConfig.dataIndex, columnNo) }, on: { "blur": function($event) {
        return _vm.onBlurEntity(rowConfig.dataIndex, record);
      }, "change": function($event) {
        return _vm.onChangeDataCell(columnNo, rowConfig.dataIndex, $event);
      } }, nativeOn: { "click": function($event) {
        _vm.onEntryInputClick(_vm.getTabIndex(rowConfig.dataIndex, columnNo));
      }, "dblclick": function($event) {
        return _vm.onClearOverwritten(rowConfig.dataIndex, record, _vm.uneditable || rowConfig.disabled);
      } } })], 1);
    })], 2);
  })], 2), _c("a-menu", { attrs: { "slot": "overlay" }, slot: "overlay" }, [_c("a-menu-item", { key: "copy", on: { "click": _vm.onCopy } }, [_vm._v("Copy")]), _c("a-menu-item", { key: "paste", on: { "click": _vm.onPaste } }, [_vm._v("Paste")]), _c("a-menu-item", { key: "delete", on: { "click": _vm.onDelete } }, [_vm._v("Delete")])], 1)], 1)], 1), _vm.tblFreezeColumns.length > 0 ? _c("div", { staticClass: "freeze-cols__container" }, [_c("table", { staticClass: "freeze-cols" }, [_c("tr", _vm._l(_vm.tblFreezeColumns, function(header, index) {
    return _c("th", { key: index, class: [
      header.type == "criteria-group" ? _vm.isCollapsed ? "" : "d-none" : ""
    ], style: {
      width: header.width,
      backgroundColor: header.headerBgColor,
      height: "33px"
    } }, [_vm._v(" " + _vm._s(header.title) + " ")]);
  }), 0), _vm._l(_vm.tblRows, function(rowConfig, rowIndex) {
    return _c("tr", { key: rowIndex }, [rowConfig.criteriaGroup && rowConfig.criteriaGroup.rowSpan > 0 ? _c("td", { staticClass: "criteria-group", class: [_vm.isCollapsed ? "" : "d-none"], attrs: { "rowspan": rowConfig.criteriaGroup.rowSpan } }, [_vm._v(" " + _vm._s(rowConfig.criteriaGroup.text) + " ")]) : _vm._e(), rowConfig.criteria ? _c("td", { staticClass: "criteria" }, [_vm._v(" " + _vm._s(rowConfig.criteria) + " ")]) : _vm._e()]);
  })], 2)]) : _vm._e(), _c("div", { staticClass: "free-cols-right__container" }, [_c("table", { staticClass: "free-cols-right__table" }, [_c("tr", [_c("td", [_vm.isCollapsed ? [_c("div", { staticClass: "collapse-expand__icon" }, [_c("a-icon", { style: { fontSize: "22px", color: "#5c6c7f" }, attrs: { "id": "expand-icon", "type": "plus-circle", "title": "Show more columns" }, on: { "click": function($event) {
    return _vm.onClickAddColumns();
  } } })], 1)] : [_c("div", { staticClass: "collapse-expand__icon" }, [_c("a-icon", { style: { fontSize: "22px", color: "#5c6c7f" }, attrs: { "id": "collapse-icon", "type": "minus-circle", "title": "Hide additional columns" }, on: { "click": function($event) {
    return _vm.onClickHideColumns();
  } } })], 1)]], 2)])])])]);
};
var staticRenderFns = [];
var ColorfulEditableTable_vue_vue_type_style_index_0_lang = "";
const apiUrl = "#{VUE_APP_API_URL}#";
const KEY_CODES = {
  down: 40,
  right: 39,
  up: 38,
  left: 37,
  enter: 13,
  tab: 9
};
const SHELF_TACTIC_ID = 1;
const __vue2_script = {
  name: "ColorfulEditableTable",
  directives: {
    scroll: {
      inserted: (el, binding) => {
        let f = (event) => {
          if (binding.value(event, el)) {
            el.removeEventListener("scroll", f);
          }
        };
        el.addEventListener("scroll", f);
      }
    }
  },
  inject: ["optionsProps", "parametersProps", "selectedScenarioIds"],
  props: {
    tblWidth: {
      type: String,
      default: "100%"
    },
    tblColumns: {
      type: Array,
      default: null
    },
    tblRows: {
      type: Array,
      default: null
    },
    tblData: {
      type: Array,
      default: null
    },
    tblName: {
      type: String,
      default: ""
    },
    gapX: {
      type: Number,
      default: 0
    },
    gapY: {
      type: Number,
      default: 0
    },
    uneditable: {
      type: Boolean,
      default: false
    },
    syncCollapse: {
      type: Boolean,
      default: true
    },
    allowClearHeader: {
      type: Boolean,
      default: false
    },
    disableHeader: {
      type: Boolean,
      default: false
    },
    isAccountGroup: {
      type: Boolean,
      default: false
    },
    isShowDeltaField: {
      type: Boolean,
      default: false
    },
    poId: {
      type: [String, Number],
      default: null
    },
    planId: {
      type: [String, Number],
      default: null
    },
    isOnline: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      apiUrl,
      defaultBaseVolume: void 0,
      tcs: null,
      tcsBuffer: null,
      pastedData: [],
      newValues: [],
      scenarioDetails: [],
      promoTacticData: [],
      invisibleColumns: [],
      isCollapsed: true,
      minTabIndex: 100,
      tabIndex: null
    };
  },
  computed: {
    inputs() {
      return this.tblRows ? this.tblRows.map((item) => item.dataIndex) : [];
    },
    tblFreezeColumns() {
      if (!this.tblColumns || this.tblColumns.length === 0)
        return [];
      return this.tblColumns.filter((item) => item.fixed);
    },
    headerLength() {
      return this.tblColumns.length;
    },
    maxTabIndex() {
      return this.inputs.length * this.headerLength + this.minTabIndex - 1;
    },
    tacticMultiBuyId() {
      const tacticMultibuy = this.promoTacticData.reduce((totalObj, currentObj) => {
        if (currentObj.description == PROMO_TACTIC_MULTIBUY || currentObj.description == PROMO_TACTIC_OOG_MULTIBUY)
          totalObj.push(currentObj.id);
        return totalObj;
      }, []);
      if (tacticMultibuy && tacticMultibuy.length)
        return tacticMultibuy;
      return 5;
    },
    listParameters() {
      return this.parametersProps.crud.getList();
    },
    parameterEntity() {
      return this.parametersProps.crud.getEntityForm("editForm");
    }
  },
  watch: {
    tblData(newVal) {
      if (this.tcs) {
        this.destroyTcs();
      }
      this.scenarioDetails = this._.cloneDeep(newVal);
      this.storeTblData(this.tblName, newVal);
      this.$nextTick(() => {
        this.initTcs();
      });
      this.remapColumnTitle(newVal);
      this.getDefaultBaseVolume();
    },
    syncCollapse(newVal) {
      this.isCollapsed = newVal;
      if (newVal) {
        this.hideAdditionalColumns();
      } else {
        this.showAllColumns();
      }
    }
  },
  mounted() {
    this.scenarioDetails = this.tblData;
    this.storeTblData(this.tblName, this.tblData);
    this.getPromoTactic();
    this.invisibleColumns = this.getIndexOfInvisibleItems(this.tblColumns);
    this.remapColumnTitle(this.tblData);
    this.$nextTick(() => {
      this.initTcs();
    });
    this.setPromoEntryKeyActions();
    this.getDefaultBaseVolume();
  },
  beforeDestroy() {
    this.destroyTcs();
    EventBus.$off("change-parameters-evt");
    EventBus.$off("pasted-data-into-table");
  },
  methods: {
    lastColumnIndex() {
      const totalColumns = this.tblColumns.length;
      const totalInvisibleColumns = this.invisibleColumns.length;
      return totalColumns - totalInvisibleColumns - 1;
    },
    getIndexOfInvisibleItems(columns) {
      let indexOfInvisibleItem = [];
      if (!columns || !columns.length)
        return indexOfInvisibleItem;
      columns.forEach((item, index) => {
        if (item.hidden) {
          indexOfInvisibleItem.push(index);
        }
      });
      return indexOfInvisibleItem;
    },
    initTcs() {
      const tcsOptions = {
        deselectOutTableClick: true,
        ignoreThead: true,
        ignoreTfoot: true,
        selectIgnoreClass: false,
        getCellFn: (cell) => this.parseDataForCopying(cell),
        setCellFn: (cell, data, coord) => this.parseDataForPasting(cell, data, coord)
      };
      const buffer = new TableCellSelector.Buffer();
      this.tcsBuffer = buffer;
      this.tcs = new TableCellSelector(this.$refs.poTbl, tcsOptions, buffer);
    },
    destroyTcs() {
      this.tcsBuffer.destroy();
      this.tcs.destroy();
    },
    handleScroll(evt) {
      const sLeft = evt.target.scrollLeft;
      this.$emit("scroll", sLeft);
    },
    formatData(val) {
      if (isNaN)
        return val;
      return +val;
    },
    onBlurEntity(dataIndex, record) {
      if (dataIndex === "baseVolume" && !record.baseVolume)
        this.setBaseVolumeToDefault(record);
      this.$emit("change", this.scenarioDetails);
    },
    onClearOverwritten(dataIndex, record, disabled) {
      if (dataIndex === "baseVolume" && !isNaN(this.defaultBaseVolume) && !disabled) {
        record[dataIndex] = this.defaultBaseVolume;
        record.overridden[dataIndex] = false;
        this.tabIndex = null;
      }
    },
    onChangeHeader(index, tacticId) {
      if (this._.isNil(this.scenarioDetails[index]))
        return;
      if (tacticId && !this.tacticMultiBuyId.includes(tacticId)) {
        this.scenarioDetails[index].redemption = 100;
        this.scenarioDetails[index].redemptionQty = 1;
      } else {
        this.scenarioDetails[index].redemption = null;
        this.scenarioDetails[index].redemptionQty = null;
      }
      this.storeValue(index, "tacticId", tacticId);
      this.storeTblData(this.tblName, this.scenarioDetails);
    },
    onChangeDataCell(columnNo, key, value) {
      this.storeValue(columnNo, key, value);
    },
    setBaseVolumeToDefault(scenarioDetail, ignoreCheckOverridden = false) {
      const { avgPromoPrice, tacticId, freqWeeks, overridden } = scenarioDetail;
      if (avgPromoPrice && tacticId && freqWeeks && (ignoreCheckOverridden || !overridden.baseVolume) && !this.isOnline) {
        scenarioDetail.baseVolume = this.defaultBaseVolume;
        scenarioDetail.overridden.baseVolume = false;
      }
    },
    storeValue(index, key, value) {
      if (this.scenarioDetails) {
        let scenarioDetail = this.scenarioDetails[index];
        const newValue = this.formatData(value);
        scenarioDetail[key] = newValue;
        switch (key) {
          case "tacticId":
            this.tblColumns[index + this.gapX].title = value;
            this.setBaseVolumeToDefault(scenarioDetail);
            break;
          case "baseVolume":
            if (!value || value == this.defaultBaseVolume)
              scenarioDetail.overridden.baseVolume = false;
            else if (value != this.defaultBaseVolume)
              scenarioDetail.overridden.baseVolume = true;
            else if (value == this.defaultBaseVolume)
              scenarioDetail.overridden.baseVolume = false;
            break;
          case "avgPromoPrice":
            if (scenarioDetail.tacticId === SHELF_TACTIC_ID && !this.isOnline) {
              this.scenarioDetails.forEach((scenario) => {
                const { avgPromoPrice, tacticId, freqWeeks } = scenario;
                if (!avgPromoPrice || !tacticId || !freqWeeks) {
                  scenario.baseVolume = null;
                  scenario.overridden.baseVolume = false;
                } else
                  this.setBaseVolumeToDefault(scenario, true);
              });
            } else
              this.setBaseVolumeToDefault(scenarioDetail);
            break;
          case "freqWeeks":
            this.setBaseVolumeToDefault(scenarioDetail);
            break;
        }
        this.$emit("change", this.scenarioDetails);
      }
    },
    storeTblData(key, tblData) {
      this.optionsProps.crud.updateEntity("editForm", {
        resourceKey: key,
        value: tblData
      });
    },
    async getDefaultBaseVolume() {
      const url = `${this.apiUrl}/promotion-planning/common/basevolume`;
      const { data } = await this.axios.get(url, {
        params: {
          promotionOptimisationId: this.poId || this.$route.params.id,
          planId: this.planId || this.selectedScenarioIds[this.tblName].id
        }
      });
      if (data) {
        this.defaultBaseVolume = data.baseVolume;
      }
    },
    getParameters(tblName) {
      let editingScenario = this.selectedScenarioIds[tblName].id;
      let parameters = {
        price: 0,
        tradingTerms: 0
      };
      if (this.parameterEntity && editingScenario === this.parameterEntity.planId) {
        return __spreadValues(__spreadValues({}, parameters), this.parameterEntity);
      } else {
        const found = this.listParameters.find((item) => item.planId === editingScenario);
        if (!found)
          return parameters;
        return __spreadValues(__spreadValues({}, parameters), found);
      }
    },
    onContextMenuOpen(e) {
      const dataset = e.currentTarget.dataset;
      if (this.$refs.poTbl.querySelector(".tcs-select") || !dataset)
        return;
      const coordX = +dataset.coordX;
      const coordY = +dataset.coordY;
      this.tcs.select([coordY, coordX], [coordY, coordX]);
    },
    onCopy() {
      this.tcs.copy();
    },
    parseDataForCopying(cell) {
      if (!cell || !cell.dataset)
        return;
      return cell.dataset.value;
    },
    async onPaste() {
      const selectedCells = this.$refs.poTbl.querySelector(".tcs-select");
      if (!selectedCells || !selectedCells.dataset)
        return;
      const coordX = +selectedCells.dataset.coordX;
      const coordY = +selectedCells.dataset.coordY;
      try {
        const clipboardText = await window.navigator.clipboard.readText();
        this.tcs.paste(SheetClip.parse(clipboardText), [coordY, coordX]);
      } catch (error) {
        console.log(error);
      }
    },
    parseDataForPasting(cell, data) {
      if (this._.isNil(data))
        return;
      let copiedValue;
      const { field, optionId, planId, coordX } = cell.dataset;
      const itemIndex = +coordX - this.gapX;
      if (data === 0 || data === "0" || isNaN(data)) {
        copiedValue = data;
      } else if (typeof data === "string" && !data.length) {
        copiedValue = null;
      } else {
        copiedValue = +data;
      }
      const cellInfo = {
        index: itemIndex,
        [field]: copiedValue,
        optionId: +optionId,
        planId: +planId
      };
      if (field === "baseVolume") {
        Object.assign(cellInfo, {
          overridden: { baseVolume: data !== this.defaultBaseVolume }
        });
      }
      this.pastedData.push(cellInfo);
      this.afterPaste();
    },
    afterPaste: _.debounce(function() {
      this.$emit("pasted", this.pastedData);
      this.pastedData = [];
    }, 50),
    async getPromoTactic() {
      const { data } = await this.axios.get(`${this.apiUrl}/promotion-planning/common/tactic`);
      this.promoTacticData = [...data];
    },
    onDelete() {
      this.tcs.clear();
    },
    onClickAddColumns() {
      this.isCollapsed = false;
      this.showAllColumns();
      const expandEvent = new CustomEvent("syncCollapse", {
        bubbles: true,
        detail: {
          isCollapsed: false
        }
      });
      const expandIconEle = document.querySelector("#expand-icon");
      expandIconEle.dispatchEvent(expandEvent);
    },
    onClickHideColumns() {
      this.isCollapsed = true;
      this.hideAdditionalColumns();
      const collapseEvent = new CustomEvent("syncCollapse", {
        bubbles: true,
        detail: {
          isCollapsed: true
        }
      });
      const collapseIconEle = document.querySelector("#collapse-icon");
      collapseIconEle.dispatchEvent(collapseEvent);
    },
    showAllColumns() {
      this.invisibleColumns.length = 0;
    },
    hideAdditionalColumns() {
      this.invisibleColumns = this.getIndexOfInvisibleItems(this.tblColumns);
    },
    remapColumnTitle(arr) {
      if (arr.length > 0) {
        arr.forEach((ele, index) => {
          this.tblColumns[index + this.gapX].title = ele.tacticId;
        });
      }
    },
    getTabIndex(dataIndex, entryIndex) {
      const inputIndex = this.inputs.findIndex((input) => input === dataIndex);
      if (inputIndex < 0)
        return null;
      return entryIndex + inputIndex * this.headerLength + this.minTabIndex;
    },
    onEntryInputClick(tabIndex) {
      this.tabIndex = tabIndex;
    },
    setPromoEntryKeyActions() {
      window.addEventListener("keydown", (e) => {
        const { keyCode } = e;
        const { down, right, up, left, enter, tab } = KEY_CODES;
        let { tabIndex } = this;
        switch (keyCode) {
          case down:
          case enter:
            tabIndex += this.headerLength;
            break;
          case right:
            tabIndex += 1;
            break;
          case up:
            tabIndex -= this.headerLength;
            break;
          case left:
            tabIndex -= 1;
            break;
          case tab:
            e.preventDefault();
            tabIndex += 1;
            break;
        }
        if (tabIndex >= this.minTabIndex && tabIndex <= this.maxTabIndex) {
          this.tabIndex = tabIndex;
        }
        this.$nextTick(() => {
          const inputFocusing = document.querySelector(".colorful-editable-table__container .colorful-editable-table td.focusing");
          const main = document.querySelector(".ant-layout-content");
          const footer = document.querySelector(".promo-detail__footer");
          const header = document.querySelector(".ant-layout-header");
          if (inputFocusing && main && footer && header) {
            const inputFocusingRect = inputFocusing.getBoundingClientRect();
            const inputBottom = inputFocusingRect.bottom;
            const inputTop = inputFocusingRect.top;
            const windowHeight = window.innerHeight;
            const inputHeight = inputFocusing.offsetHeight;
            const footerHeight = footer.offsetHeight;
            const headerHeight = header.offsetHeight;
            if (inputBottom > windowHeight - footerHeight) {
              main.scrollTop += inputBottom - windowHeight + inputHeight + footerHeight;
            } else if (inputFocusingRect.top - headerHeight < 0) {
              main.scrollTop += inputTop - headerHeight;
            }
          }
        });
      });
    },
    onEntryTableBlur() {
      this.tabIndex = null;
    }
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var ColorfulEditableTable = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { ColorfulEditableTable as default };
